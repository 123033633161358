.headerSection {
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
    background-image: url("../../assets/header-bg.jpg");
    height: 100vh;
    height: var(--doc-height);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headerSection::before,
.headerSection::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 52%;
    clip-path: polygon(0 0, 0 100%, 100% 100%);
    background-color: #08CDFF;
    height: 50px;
}

.headerSection::after {
    left: auto;
    right: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
}

@media (max-width: 1500px) {

    .headerSection::before,
    .headerSection::after {
        height: 40px;
    }
}

@media (max-width: 1200px) {

    .headerSection::before,
    .headerSection::after {
        height: 30px;
        width: 55%;
    }
}

@keyframes goUp {
    0% {
        transform: translateY(10rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
}

.lottieAnim {
    width: 35vw;
    max-width: 40rem;
    margin-top: 2rem;
    opacity: 0;
}

@media (max-width:730px) {
    .lottieAnim {
        width: 85vw;
    }
}

.date {
    font-size: 2rem;
    font-weight: bold;
    margin-top: 2rem;
    text-shadow: 0 1px 0 #0083a4, 0 2px 0 #0083a4, 0 3px 0 #0083a4, 0 4px 0 #0083a4;
    margin-bottom: 0.2rem;
    opacity: 0;
}


.btnPath {
    stroke-dasharray: 436;
    stroke-dashoffset: 0;
    transition: 3s;
}

.svgicon {
    fill: #132533;
    stroke: #08CDFF;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    fill-rule: evenodd;
    z-index: -1;
}

.svgicon svg {
    display: block;
    width: 100%;
    height: 100%;
}

@keyframes scaleToFull {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.btnWrap {
    color: white;
    font-weight: bold;
    width: 140px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background: transparent;
    border: none;
    white-space: nowrap;
    padding: 0;
    line-height: 1;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-decoration: inherit;
    color: inherit;
    opacity: 0;
}

.btnWrap:hover .btnPath {
    stroke-dashoffset: 872;
}

.headerDesc {
    font-family: 'Barlow';
    letter-spacing: 0;
    max-width: 30rem;
    text-align: center;
    font-size: 0.9rem;
    margin: 0 1rem;
    opacity: 0.7;
    margin-top: 0.35rem;
    margin-bottom: 0.7rem;
    line-height: 1.2rem;
    opacity: 0;
}


@keyframes bannermove {
    0% {
        transform: translate(0, 0);
    }

    100% {
        transform: translate(-50%, 0);
    }
}

.sponsorsScrollWrap {
    background: #0b0e13;
    height: 20rem;
    display: flex;
    align-items: center;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.sponsorsScroll {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    overflow: hidden;
    animation: bannermove 30s linear infinite;
    position: absolute;
}

.sScrollCard {
    height: 6rem;
    min-width: 10rem;
    background-image: -webkit-linear-gradient(90deg, #0c0e12 0%, rgba(4, 67, 88, 0.5) 100%);
    border-radius: 8px;
    border: 1px solid rgba(1, 201, 252, 0.3);
    padding: 0.5rem 1.5rem;
}

.sScrollCard img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
}

.prizesSec {
    width: 100%;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    background-image: url("../../assets/rewards-bg.jpg");
    height: 100vh;
}

.headerText {
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 2.5rem;
}

.headerText>div:nth-of-type(1) {
    color: #08CDFF;
    font-family: 'Barlow';
    letter-spacing: 0.1rem;
    font-weight: bold;
    margin-bottom: 5px;
    opacity: 0;
}

.headerText>div:nth-of-type(2) {
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 0 1px 0 #0083a4, 0 2px 0 #0083a4, 0 3px 0 #0083a4, 0 4px 0 #0083a4;
    opacity: 0;
}

.headerText::after {
    content: "";
    display: block;
    background-image: url("../../assets/titleshape.svg");
    width: 65px;
    height: 5px;
    margin: 15px auto 0;
}

@keyframes goDown {
    0% {
        transform: translateY(-5rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0rem);
        opacity: 1;
    }
}

.decoratedBtn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border: 2px solid #08CDFF;
    white-space: nowrap;
    font-size: 15px;
    padding: 14px 35px;
    line-height: 1;
    position: relative;
    overflow: hidden;
    font-weight: bold;
    font-family: 'Barlow';
    transition: .5s;
    cursor: pointer;
}

.decoratedBtn:hover {
    background: #08CDFF;
    color: #201f2a;
}

.decoratedBtn::before,
.decoratedBtn::after {
    content: "";
    position: absolute;
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("../../assets/btndecoration.png");
    width: 15px;
    height: 51px;
    pointer-events: none;
}

.decoratedBtn::after {
    left: auto;
    right: -2px;
    transform: translateY(-50%) rotate(180deg);
}

.decoratedBtnWrap {
    display: flex;
    gap: 1.75rem;
}

@media (max-width: 620px) {
    .decoratedBtnWrap {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .decoratedBtn {
        width: fit-content;
    }
}

.prizesTab {
    position: relative;
    width: 20rem;
    margin: 5rem 0;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
}

.prizesTab::before,
.prizesTab::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, transparent 0%, #08CDFF 50%, transparent 100%);
}

.prizesTab::after {
    top: auto;
    bottom: 0;
}
@font-face {
    font-family: arcane;
    src: url("./assets/Arcane\ Nine.otf");
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    box-sizing: border-box;
    font-family: 'arcane', sans-serif;
    letter-spacing: 2px;
    background: #0F161C;
    color: white;
    height: 200vh;
    max-width: 100vw;
    overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #2e3f4d;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #08CDFF;
}